<script>
import { mapState, mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import PaginationCustom from '@/components/table/Pagination.vue'
import mixinHelper from '@/mixins/helper'

export default {
   name: 'Aktivitas',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
   },
   mixins: [mixinHelper],
   data() {
      return {
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'Nama',
               field: 'name',
               sortable: false,
               thClass: 'text-center align-middle',
               filterOptions: {
                  enabled: true,
                  placeholder: 'Cari',
               },
            },
            {
               label: 'IP Address',
               field: 'ip_address',
               sortable: false,
               thClass: 'text-center align-middle',
               filterOptions: {
                  enabled: true,
                  placeholder: 'Cari',
               },
            },
            {
               label: 'Login',
               field: 'created_at',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Aktivitas Terakhir',
               field: 'updated_at',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],
         options: {
            status: [
               { value: null, text: '-' },
               { value: true, text: 'Aktif' },
               { value: false, text: 'Tidak Aktif' },
            ],
         },
      }
   },
   computed: {
      ...mapState({
         myData: state => state.User.aktivitas,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      relativeTime() {
         return date => (this.$moment(date) ? this.$moment(date).fromNow() : date)
      },
   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'User/getAktivitas',
      }),
      onRefresh() {
         setTimeout(() => {
            this.loadItems()
         }, 100)
      },
      // VUE GOOD TABLE
      async loadItems() {
         await this.getData(this.filter)
      },
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data Aktivitas">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="12">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1 align-self-end">
                     <b-button
                        v-b-tooltip.hover.top="'Refresh'"
                        variant="outline-secondary"
                        size="sm"
                        @click="onRefresh()"
                     >
                        <b-spinner v-if="isLoading" small />
                        <feather-icon v-if="!isLoading" icon="RefreshCwIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item @click="onRefresh()">
                           Refresh
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>
            <!-- FILTER -->
         </b-row>

         <!-- table -->
         <vue-good-table
            ref="goodTable"
            :columns="columns"
            :rows="myData"
            :line-numbers="true"
            :pagination-options="{
               enabled: true,
               mode: 'pages',
            }"
         >
            <template slot="table-row" slot-scope="props">
               <span v-if="props.column.field == 'name'">
                  {{ props.row.name }}
               </span>
               <span v-if="props.column.field == 'ip_address'">
                  {{ props.row.ip_address }}
               </span>
               <span v-if="props.column.field == 'created_at'">
                  {{ props.row.created_at | moment("DD-MM-YYYY | HH:mm:ss") }}
                  <br>
                  <small><b>{{ relativeTime(props.row.created_at) }}</b></small>
               </span>
               <span v-if="props.column.field == 'updated_at'">
                  {{ props.row.updated_at | moment("DD-MM-YYYY | HH:mm:ss") }}
                  <br>
                  <small><b>{{ relativeTime(props.row.updated_at) }}</b></small>
               </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom
                  :total="props.total"
                  :page-length="pageLength"
                  :page-options="pageOptions"
                  :page-changed="props.pageChanged"
                  :per-page-changed="props.perPageChanged"
               />
            </template>
         </vue-good-table>
      </b-card-code>
   </div>
</template>

<style scoped>
table>tbody>tr>td>span>hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}

.nowrap {
   white-space: nowrap;
}
</style>
